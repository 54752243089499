<template>
  <div class="Reconciliation">
    <div class="container">
      <div class="title">逾期账款</div>
      <div class="form">
        <div class="cell">
          <div class="label">客 户</div>
          <div class="content">
            {{ entity.cCusName }}
          </div>
        </div>
        <div class="cell">
          <div class="label">电 话</div>
          <div class="content">
            {{ entity.cCusPhone ? entity.cCusPhone : "" }}
          </div>
        </div>
        <div class="cell">
          <div class="label">地 址</div>
          <div class="content">
            {{ entity.cCusAddress ? entity.cCusAddress : "" }}
          </div>
        </div>
        <div class="cell">
          <div class="label">逾期余额</div>
          <div class="content cRight">
            {{ momenyFormat(entity.TotalOverdue) }}
          </div>
        </div>
      </div>
      <div class="table">
        <div class="tr">
          <div class="th td">日期</div>
          <div class="th td">逾期天数</div>
          <div class="th td">逾期余额</div>
          <div class="th td action">操作</div>
        </div>
        <div class="tr" v-if="data.length > 0">
          <!-- <div class="td">初期欠款</div> -->
          <div class="td action"></div>
          <!-- <div class="td">{{ momenyFormat(data[0].PreviousOwing) }}</div> -->
          <div class="td action"></div>
        </div>
        <div
          v-for="item in data"
          :key="item.Id"
          :class="item.Type == 3 ? 'warn tr' : 'tr'"
        >
          <div class="td">
            {{ item.CreateTime?item.CreateTime.substring(0, 10):"" }}
          </div>
          <div class="td">
            {{ item.OverdueDay }}
          </div>
          <div class="td">
            {{ momenyFormat(item.Yumoeney) }}
          </div>
          <div class="td action">
            <span @click="toList(item)" v-if="item.Type != 0">详情</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      v-loading="loading"
      title="详细信息"
      :visible.sync="dialogVisible"
      width="90%"
    >
      <h3 class="allPrice">
        <span>合计:</span>
        <span>{{ momenyFormat(allPrice) }}</span>
      </h3>
      <div style="overflow-y: scroll; max-height: 50vh">
        <div class="subItem" v-for="item in subData" :key="item.Id">
          <div class="imgBox">
            <img :src="item.Picture" alt="" />
          </div>
          <div class="content">
            <div class="top">{{ item.InventName }}</div>
            <div class="bottom">
              单价: {{ momenyFormat(item.Price) }} × {{ item.Quantity }}
            </div>
          </div>
          <div class="price">{{ momenyFormat(item.Money) }}</div>
        </div>
      </div>
    </el-dialog>

    <!-- 小票页面组件 -->
    <ReceiptPage ref="receiptPage" :parentObj="{ ...this }" />
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
// 引入小票页面
import ReceiptPage from "../Overdue/Receipt.vue";
export default {
  components: {
    ReceiptPage,
  },
  data() {
    return {
      entity: {},
      data: [],
      query: {},
      subData: [],
      dialogVisible: false,
      loading: false,
      title: "",
    };
  },
  mounted() {
    this.query = JSON.parse(this.$route.query.data);
    this.title =
      this.query.title == ""
        ? "尊敬的客户，您本月账单已逾期，如已支付请忽略。"
        : this.query.title;

    sessionStorage.setItem("token", this.query.token);
    this.getTheData();
    this.getShareInfo();
  },
  computed: {
    allPrice() {
      let num = 0;
      this.subData.forEach((item) => (num += Number(item.Money) || 0));
      return num;
    },
  },
  methods: {
    // 获取详情
    toList(obj) {
      console.log("123", obj.Id);
      // 打开小票页面
      this.$refs.receiptPage.openForm(obj.Id, true);
      // if (obj.subData) {
      //   this.subData = obj.subData;
      //   this.dialogVisible = true;
      //   return;
      // }
      // this.loading = true;
      // this.$http
      //   .post("/BO/BIlling/GetTheData", {
      //     id: obj.Id,
      //   })
      //   .then((res) => {
      //     this.loading = false;
      //     if (!res.Success) return this.$message.error(res.Msg);
      //     if (res.Data == null) {
      //       this.$message.error('暂无明细');
      //       return
      //     }
      //     obj.subData = JSON.parse(res.Data.Content);
      //     this.subData = obj.subData;
      //     this.dialogVisible = true;
      //   });
    },
    getTheData() {
      this.$http
        .post("/BO/BIlling/GetOverdueSharing", {
          Id: this.query.Id,
          CompanyId: this.query.CompanyId,
          CustomerId: this.query.CustomerId,
          days: this.query.days,
        })
        .then((res) => {
          console.log(
            "获取最终欠款数据最终欠款数据最终欠款数据最终欠款数据最终欠款数据",
            res
          );
          if (!res.Success) return this.$message.error(res.Msg);

          this.entity = res.Data;
          this.data = res.Data.details;
        });
    },
    momenyFormat(num) {
      num = Number(num);
      if (isNaN(num)) return "0.00";
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
    },
    // 获取微信认证
    async getShareInfo() {
      let url = location.href.split("#")[0]; // 只需要传地址就好，其他后端都会写好来
      let ticketData = await this.$http.get(
        "/BO/BillList/GetWxSign?url=" + url
        // "/BO/BillList/GetWxSign?url=https://psq.yoojet.com/"
      );
      let arr;
      if (ticketData.Data.length !== 0) {
        arr = ticketData.Data.split(",");
      }

      const config = {
        appId: "wxde354ac424a66adc",
        timestamp: arr[2],
        nonceStr: arr[1],
        signature: arr[0],
      };
      wx.config({
        debug: false, //是否打开debug调试模式。
        appId: config.appId, // appID 公众号的唯一标识
        timestamp: config.timestamp, // 生成签名的时间戳
        nonceStr: config.nonceStr, //  生成签名的随机串
        signature: config.signature, // 生成的签名
        jsApiList: ["onMenuShareAppMessage"],
      });
      wx.ready(() => {
        var shareData = {
          title: "逾期提醒",
          desc: this.title,
          link: location.href,
          imgUrl: "https://psq.yoojet.com/wx.png",
          success: function (res) {
            console.log("分享好友成功");
          },
          cancel: function (err) {
            console.log(err, "错误信息");
          },
        };
        //点击要去分享
        wx.onMenuShareAppMessage(shareData);
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog__body {
  padding-top: 0;
}

.warn {
  background-color: #ff9999;
}

.Reconciliation {
  padding: 10px;
  padding-bottom: 30px;
  color: #000;

  .imgBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 180px;
    overflow: hidden;
    width: 100%;
  }

  .img {
    transform: rotate(-90deg);
    width: 160px;
  }

  .container {
    width: 100%;

    .title {
      font-weight: bold;
      text-align: center;
    }

    .form {
      margin: 15px 0;
      border-bottom: 1px solid #ccc;
      line-height: 2.5;

      .cell {
        border: 1px solid #ccc;
        border-bottom: none;
        display: flex;

        .label {
          width: 75px;
          border-right: 1px solid #ccc;
          padding-right: 10px;
          text-align: right;
          background-color: #fafafa;
        }

        .content {
          flex: 1;
          padding: 0 10px;
          text-align: right;
        }

        .cRight {
          text-align: right;
        }
      }
    }

    .table {
      border-bottom: 1px solid #ccc;

      .tr {
        border: 1px solid #ccc;
        border-bottom: none;
        display: flex;
        align-items: center;
        height: 100%;

        .td {
          padding: 5px;
          height: 100%;
          // border-right: 1px solid #ccc;
          flex: 1;
          text-align: center;
          font-size: 12px;
        }

        .th {
          font-weight: bold;
          line-height: 2;
          font-size: 15px;
        }

        .action {
          flex: inherit;
          width: 40px;

          span {
            color: blue;
          }
        }
      }
    }
  }

  .allPrice {
    display: flex;
    padding: 10px 0;
    justify-content: space-between;
  }

  .subItem {
    border-bottom: 1px solid #f5f5f5;
    display: flex;
    align-items: center;

    .imgBox {
      width: 50px;
      height: 50px;
      margin-right: 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .content {
      flex: 1;
    }
  }
}
</style>